'use client';

import { Fragment, useState } from 'react';
import { usePathname } from 'next/navigation';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';

import { ILayoutHeader } from '@/types/layout';
import { twMerge } from 'tailwind-merge';

export const LayoutHeader = (props: ILayoutHeader) => {
  const [isMenuMobileOpen, setIsMenuMobileOpen] = useState(false);
  const pathname = usePathname();

  return (
    <div className="bg-gray-900">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">RV Show USA</span>
            <img className="h-12 w-auto " src={props.logoUrl} alt="RV Show USA" />
          </a>
        </div>
        <div className="flex md:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            onClick={() => setIsMenuMobileOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <FontAwesomeIcon icon={faBars} className="h-6 w-6 text-white" />
          </button>
        </div>
        <div className="hidden md:flex md:gap-x-4 lg:gap-x-12">
          {props.menu.items.map((item, index) => {
            return (
              <div key={index} className="flex items-center gap-4">
                <a
                  href={item.url}
                  className={twMerge('text-white hover:opacity-75', pathname === item.url ? 'font-bold' : '')}
                >
                  {item.title}
                </a>
              </div>
            );
          })}
        </div>
      </nav>
      {/* Mobile */}
      <Transition show={isMenuMobileOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 min-[768px]:hidden"
          onClose={() => setIsMenuMobileOpen(false)}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-neutral-600 bg-opacity-75" onClick={() => setIsMenuMobileOpen(false)} />
          </TransitionChild>
          <TransitionChild
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col pr-12 bg-neutral-900">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-1 pt-2">
                  <div
                    className={
                      'flex items-center justify-center h-10 w-10 rounded-full focus:outline-none cursor-pointer'
                    }
                    onClick={() => setIsMenuMobileOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <FontAwesomeIcon icon={faXmark} className="h-6 w-6 text-white" />
                  </div>
                </div>
              </TransitionChild>
              <div className="flex px-6 pt-6 pb-4">
                <a href="/" className="outline-none">
                  <img className="h-12 w-auto " src={props.logoUrl} alt="RV Show USA" />
                </a>
              </div>
              <div className="flex-1 py-2 overflow-y-auto -mr-12">
                <ul className="flex flex-col">
                  {props.menu.items.map((item, index) => (
                    <li key={`sidebar-${index}`}>
                      <a
                        className={twMerge(
                          'block py-3 px-4 text-neutral-300 focus:outline-none hover:bg-gray-800',
                          pathname === item.url ? 'bg-gray-800' : '',
                        )}
                        href={item.url}
                      >
                        {item.title}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </TransitionChild>
        </Dialog>
      </Transition>
    </div>
  );
};
